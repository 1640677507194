import { lazy, Fragment, useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import * as S from "./styles";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Container = lazy(() => import("../../common/Container"));

const Footer = ({ t }) => {
  const [date , setDate] = useState();
  const getYear = () =>  setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <Fragment>
      <S.Extra>
        <Container border="true" style={{ padding: 0 }}>
          <Row
            type="flex"
            justify="flex-start"
            align="middle"
            style={{ paddingTop: 10 }}
            gutter={24}
          >
            <Col xl={4}>
              <S.NavLink to="/">
                <S.LogoContainer>
                  <SvgIcon
                    src="logo.svg"
                    aria-label="homepage"
                    width={101}
                    height={64}
                  />
                </S.LogoContainer>
              </S.NavLink>
            </Col>
            <Col xl={20}>
              <S.FooterDesc>
                © {date} National Care Dental Health Plan LLC d/b/a NCD
                <Divider style={{ margin: '5px 0px' }} />
                At National Care Dental Health Plan LLC d/b/a NCD, we are strongly committed to protecting your privacy. As explained in our TRUSTe-certified Privacy Policy and our Terms of Use, we do not sell, trade, or give away your personal information without your permission, and we respect your choices about whether and how to receive marketing messages from us.
              </S.FooterDesc>
            </Col>
          </Row>
        </Container>
      </S.Extra>
    </Fragment>
  );
};

export default Footer;
