import { useState, Fragment, lazy } from 'react'
import { Row, Col, Select, Menu, Dropdown, Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { useMatchMedia } from '../../common/mediaquery'
import queryString from 'query-string'

import * as S from './styles'

const SvgIcon = lazy(() => import('../../common/SvgIcon'))

const Header = ({ t }) => {
    const isMobile = useMatchMedia('(min-width:800px)', true)

    const menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" href="https://ncd.com/dentists" rel="noreferrer">
                    Dental Search
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" href="https://www.vsp.com/eye-doctor" rel="noreferrer">
                    Vision Search
                </a>
            </Menu.Item>
        </Menu>
    )

    return (
        <S.Header>
            <S.Container>
                <Row type="flex" justify="space-between" gutter={24}>
                    <Col xl={16} xs={14}>
                        <S.LogoContainer aria-label="homepage">
                            <Row type="flex" gutter={24} align="middle" style={{ height: '100%' }}>
                                <Col xl={6}>
                                    <Link to="/">
                                        <SvgIcon src="logo.svg" height={80} width={150} />
                                    </Link>
                                </Col>
                                <Col xl={2} style={{ marginRight: 5 }} className="extra-logos">
                                    <p style={{ fontSize: 8 }}>Dental underwritten by</p>
                                </Col>
                                <Col xl={5} className="extra-logos">
                                    <SvgIcon
                                        src="nationwide_logo.svg"
                                        style={{ verticalAlign: 'bottom' }}
                                        height={50}
                                    />
                                </Col>
                                <Col xl={2} style={{ marginRight: 5 }} className="extra-logos">
                                    <p style={{ fontSize: 8 }}>Vision underwritten by</p>
                                </Col>
                                <Col xl={5} className="extra-logos">
                                    <SvgIcon
                                        src="vsp_logo_v2.svg"
                                        style={{ verticalAlign: 'bottom' }}
                                        height={50}
                                    />
                                </Col>
                            </Row>
                        </S.LogoContainer>
                    </Col>
                    {isMobile && (
                        <Col
                            xl={4}
                            xs={5}
                            style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
                        >
                            <Dropdown overlay={menu} placement="bottomLeft">
                                <Button
                                    style={{
                                        backgroundColor: '#469dc9',
                                        color: 'white',
                                        width: '150px',
                                        height: '40px'
                                    }}
                                >
                                    Find a Doc
                                </Button>
                            </Dropdown>
                        </Col>
                    )}
                    <Col xl={4} xs={10}>
                        <S.CallBox>
                            <span class="light-span">Need help?</span>
                            <h3>
                                <b>(888)-415-4801</b>
                            </h3>
                            <h5>Mon-Fri 8am-6pm(CST)</h5>
                        </S.CallBox>
                        {!isMobile && (
                            <Row
                                type="flex"
                                gutter={24}
                                align="middle"
                                style={{
                                    float: 'right',
                                    paddingRight: '22px',
                                    paddingBottom: '15px'
                                }}
                            >
                                <Dropdown overlay={menu} placement="bottomLeft">
                                    <Button
                                        style={{
                                            backgroundColor: '#469dc9',
                                            color: 'white',
                                            width: '150px',
                                            height: '40px'
                                        }}
                                    >
                                        Find a Doc
                                    </Button>
                                </Dropdown>
                            </Row>
                        )}
                    </Col>
                </Row>
            </S.Container>
        </S.Header>
    )
}

export default Header
